const apiKeys = {
  firebaseConfig: {
    apiKey: 'AIzaSyCP-YsQr6vWoqmQoRYuHr9vVHktm0KJ-lw',
    authDomain: 'classtracker-154ae.firebaseapp.com',
    databaseURL: 'https://classtracker-154ae.firebaseio.com',
    projectId: 'classtracker-154ae',
    storageBucket: 'classtracker-154ae.appspot.com',
    messagingSenderId: '624874241438',
  },
  githubProxyUrl: 'https://classtracker-github-proxy.herokuapp.com',
};

export default apiKeys;
